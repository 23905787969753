import { render, staticRenderFns } from "./MediaList.vue?vue&type=template&id=ebe52982&scoped=true&"
import script from "./MediaList.vue?vue&type=script&lang=js&"
export * from "./MediaList.vue?vue&type=script&lang=js&"
import style0 from "./MediaList.vue?vue&type=style&index=0&id=ebe52982&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebe52982",
  null
  
)

export default component.exports
import {QBtn,QTooltip,QSpinner,QUploader,QSpace,QUploaderAddTrigger,QCarousel,QCarouselSlide,QCarouselControl,QDialog,QCard,QCardSection,QCardActions,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QTooltip,QSpinner,QUploader,QSpace,QUploaderAddTrigger,QCarousel,QCarouselSlide,QCarouselControl,QDialog,QCard,QCardSection,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
