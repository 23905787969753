
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Action, State} from "vuex-class";
import {mapGetters} from "vuex";

import store from "@/store";
import functions from '@/functions';

import EmailAttachments from "@/components/Nylas/EmailAttachments.vue";
import AbModel from '@/components/Models/AbModel';
import BaseFieldRelatedMultiSelect from "@/components/Base/BaseFieldRelatedMultiSelect.vue";
import {asyncForEach} from "@/common/helpers/async-for-each";
import UnlayerTools, {UnlayerSettings} from '@/components/Settings/Sections/Templates/EmailTemplates/Models/UnlayerTools';
import PdfTools from "@/components/Settings/Sections/Templates/PDFgenerator/Models/PdfTools";
import ListValues from "@/components/Mixin/ListValues";
import sortList from "@/common/helpers/utilities";
import {uid} from "quasar";

@Component({
  computed: {
    ...mapGetters("user", {profile: "profile"}),
  },
  components: {
    EmailAttachments, BaseFieldRelatedMultiSelect
  }
})

export default class TemplateEmailForm extends Vue {
  @Prop(Object) formData;
  @Prop(Array) emailSettings;
  @Prop(Object) section;
  @Prop(Array) tenantEmailsTemplates;
  @Prop(Array) userEmailsTemplates;

  @Action('uploadFileToStorage') uploadFileToStorage;
  @Action('deleteFileFromStorage') deleteFileFromStorage;
  @Action('settings/saveSelectedEmailSetting') saveSelectedEmailSetting;
  @State("userProfile") profile;
  @State("userSettings") userSettings;
  @Prop(Object||null) pdfToSend;
  useTinyMCE = true;
  emailData = {
    to: '',
    from: '',
    subject: '',
    cc: '',
    bcc: '',
    message: '',
    fileIDs: []
  };
  data: any = {};
  editor: any;
  design = {name: '', id: '', ID: '', design: {}, subject: '', to: '', cc: '', bcc: '', tags: []};
  userDesigns = [];
  mergeTags = {};
  settings = new UnlayerSettings();
  showCcField = false;
  showBccField = false;
  loading = false;
  attachLoading = false;
  sizeOver = false;
  askCloseFormModal = false;
  smallModalMode = false;
  emailAction: any = {};
  showSelectContactDialog = false;
  selectedContacts: Array<string> = [];
  moduleForEmail = 'contacts';
  currentEmailField = '';
  selectEmailSettings: any = null;
  showUnlayerEditor = false;
  unlayerEditorKey = 1;
  needToUnlayerEditorRefresh = false;
  loadingDesign = false;
  oldSelectEmail = '';
  designLoaded = false;
  addToAttachBtTags = null;
  uploadingFiles = {};

  get docData() {
    return this.formData ? this.formData.data : {};
  }

  get docAttachments() {
    return this.docData && this.docData.attachments ? this.docData.attachments : [];
  }

  get localFormData() {
    return this.formData ? JSON.parse(JSON.stringify(this.formData)) : {};
  }

  get loadingData() {
    return this.loading || this.attachLoading || this.loadingDesign;
  }

  get notSend() {
    return this.loadingData || this.sizeOver || this.showUnlayerEditor;
  }

  get modalMaxWidth() {
    return this.smallModalMode ? 40 : 85
  }

  get modalMaxHeight() {
    return this.smallModalMode ? 75 : 90
  }

  get relatedQuery() {
    return this.formData?.data?.account?.ID ?
      [
        {
          field: 'account',
          relatedID: this.formData.data.account?.ID,
          relatedTo: 'account'
        }
      ] : null
  }

  beforeDestroy() {
    this.removeFromFirestore();
  }

  async beforeMount() {
    await this.getSettings();
    await this.unlayerInit();
  }

  async mounted() {
    this.getLastUsedEmailSetting();
    await this.getUserDesigns();
    this.data = await PdfTools.getFullJsonData(this.formData);
    this.emailData.from = this.profile.email;
    this.emailData.to = ListValues.getEmailAddresses(this.section.emailToFields, this.data);
    this.emailData.cc = ListValues.getEmailAddresses(this.section.emailCC_Fields, this.data);
    this.emailData.bcc = ListValues.getEmailAddresses(this.section.emailBCC_Fields, this.data);
    this.emailData.subject = ListValues.render(this.section.emailSubject, this.data);
    this.showCcField = (this.emailData.cc !== '') ? true : this.showCcField;
    this.showBccField = (this.emailData.bcc !== '') ? true : this.showBccField;

    await this.showUserDesigns();

    if (!this.selectEmailSettings) {
      store.state.alertMessage = 'no_email_settings';
      console.error('No emails settings for the user');
    }
  }

  saveSelectedEmail() {
    this.saveSelectedEmailSetting(this.selectEmailSettings);
  }

  getLastUsedEmailSetting() {
    this.selectEmailSettings = null;
    const savedSelect = this.userSettings.emails;
    if (savedSelect) {
      this.selectEmailSettings = this.emailSettings.find(s => s.ID === savedSelect.ID);
    }
    if (!this.selectEmailSettings) {
      if (this.emailSettings && this.emailSettings[0]) {
        this.selectEmailSettings = this.emailSettings[0];
      }
    }
    if(this.design && this.design['from']) {
      this.selectEmailSettings = this.emailSettings.find(s => s.ID === this.design["from"]);
    }
    this.oldSelectEmail = this.selectEmailSettings ? this.selectEmailSettings.ID : '';
    return this.selectEmailSettings;
  }

  async unlayerInit() {
    /* eslint-disable */
    // @ts-ignore: Unreachable code error
    this.editor = await unlayer.createEditor({
      id: 'editor-container',
      projectId: this.settings.projectId
    })
  }

  setTemplateSetting(design) {
    this.emailData.to = ListValues.getEmailAddresses(design.to, this.data);
    this.emailData.cc = ListValues.getEmailAddresses(design.cc, this.data);
    this.emailData.bcc = ListValues.getEmailAddresses(design.bcc, this.data);
    this.emailData.subject = ListValues.render(design.subject, this.data);
    this.showCcField = (this.emailData.cc !== '') ? true : this.showCcField;
    this.showBccField = (this.emailData.bcc !== '') ? true : this.showBccField;
    this.emailAction = design.action;
    if (design.from) {
      this.selectEmailSettings = this.emailSettings.find(s => s.ID === design.from);
    }
  }

  @Watch('selectEmailSettings')
  async handleSelect(newValue) {
    if (newValue && this.oldSelectEmail && this.oldSelectEmail !== newValue.ID) {
      await this.reUploadToNylas();
      this.oldSelectEmail = newValue.ID;
    }
  }

  onLoadDesignSelectClick() {
    if (this.needToUnlayerEditorRefresh) this.unlayerEditorKey++;
  }

  async loadDesign() {
    if (this.design.design && this.design.ID) {
      this.loadingDesign = true;
      if (this.needToUnlayerEditorRefresh) {
        await this.unlayerInit();
        this.needToUnlayerEditorRefresh = false
      }
      this.emailData.message = '';
      this.setTemplateSetting(this.design);
      await this.editor.loadDesign(this.design.design);
      this.addAttachmentsByTags();
      this.exportHtml();
    }
  }

  addAttachmentsByTags() {
    
    if (this.design && this.design.tags) {
      const attachs = this.docAttachments.filter(a => {
        let inc = false;
        (a.tags || []).forEach((at: never) => {
          if (this.design.tags.includes(at)) {
            inc = true;
          }
        })
        return inc;
      });
      if (attachs && attachs.length) {
        
        this.addToAttachBtTags = attachs;
      }
    }
  }

  onUnlayerEditorSave() {
    this.exportHtml();
    this.showUnlayerEditor = false;
    this.needToUnlayerEditorRefresh = true;
  }

  async getUserDesigns() {
    const tenantTemplates = this.tenantEmailsTemplates || [];
    const userTemplates = this.userEmailsTemplates || [];
    this.userDesigns = [].concat(userTemplates, tenantTemplates).filter((d: any) => d.module === this.formData.module);
    this.userDesigns = sortList(this.userDesigns, "name")

    

    const defaultTemplate = (this.userDesigns || []).find((d: any) => d.default);
    if (defaultTemplate) {
      this.design = defaultTemplate;
    }
    this.designLoaded = true;
  }

  async showUserDesigns() {
    if (this.design) {
      await this.loadDesign();
    }
  }

  async getSettings() {
    (this.settings as any) = await UnlayerTools.getSettings();
  }

  exportHtml() {
    this.editor.exportHtml(
      (data) => {
        const htmlData = ListValues.render(data.html, this.data) || '';
        this.emailData.message = htmlData;
        this.loadingDesign = false;
      }
    )

  }

  onReceiveResetShowForm() {
    this.askCloseFormModal = true;
  }

  onCloseFormAfterSure() {
    this.$emit("receiveResetShowForm");
  }

  get emailSender() {
    return this.selectEmailSettings ? this.selectEmailSettings.ID : null;
  }

  async removedFile(data) {
    const removedFile = data.removedFile;
    this.sizeOver = data.sizeOver;
    await this.removeFromFirestore(removedFile);
    this.emailData.fileIDs = this.emailData.fileIDs.filter((f: any) => f.fireID !== removedFile.ID);
    if (this.uploadingFiles[removedFile._uid]) {
      this.uploadingFiles[removedFile._uid] = false;
    }
    if (this.uploadingFiles[removedFile.ID]) {
      this.uploadingFiles[removedFile.ID] = false;
    }
    
  }

  async addFiles(data) {
    const files = data.files;
    const addFile = data.addFile;
    this.sizeOver = data.sizeOver;
    if (this.sizeOver) {
      store.state.alertMessage = 'over_size';
      return;
    }
    this.attachLoading = true;
    let nylasFiles = {data: []};

    // send as email
    if (!this.designLoaded) {
      this.oldSelectEmail = '';
      await this.getUserDesigns();
    }

    if (!this.selectEmailSettings) {
      this.getLastUsedEmailSetting();
    }
    // end send as email

    if (files) {
      // from dropzone
      Object.keys(files).forEach(file => {
        const f = files[file]
        f._uid = uid();
        this.uploadingFiles[f._uid] = true;
      });
      const filesToNylas = [];
      const fireFiles = await this.uploadToFirestore(files);

      await asyncForEach(fireFiles, async (f) => {
        if (this.uploadingFiles[f._uid]) {
          this.uploadingFiles[f._uid] = false;
          filesToNylas.push(f as never);
          f._uid = f.ID;
          this.uploadingFiles[f._uid] = true;
        } else {
          await this.removeFromFirestore(f);
        }
      });

      
      if (filesToNylas.length) {
        nylasFiles = await this.uploadToNylas(fireFiles);
      }
    }

    if (addFile) {
      // from attachment or media
      this.uploadingFiles[addFile.ID] = true;
      nylasFiles = await this.uploadToNylas([addFile]);
    }

    const filesIds = nylasFiles.data;
    
    filesIds.forEach((f: any) => {
      if (this.uploadingFiles[f.fireID]) {
        this.uploadingFiles[f.fireID] = false;
        this.emailData.fileIDs.push(f as never);
      }
    });
    
    
    this.attachLoading = false;
  }

  async uploadToFirestore(files) {
    const fireFiles = [];
    await asyncForEach(files, async (file) => {
      file.storageRef = 'email_temp_attachments/';
      //TODO show progress
      file.procentShowCallBack = (snapshot) => {
        const loaded = Math.min(snapshot.totalBytes, snapshot.bytesTransferred)
        
        //this.uploadedSize += loaded - file.__uploaded
        //this.updateFile(file, 'uploading', loaded)
      };
      const uploadedFile = await this.uploadFileToStorage(file);
      uploadedFile.removeFromStorage = true;
      uploadedFile._uid = file._uid;
      (fireFiles as any).push(uploadedFile);
      file.ID = uploadedFile.ID;
    });
    return fireFiles;
  }

  async uploadToNylas(files) {
    // TODO dev mode
    //functions.useFunctionsEmulator("http://localhost:5001");
    const uploadFilesToNylas = functions.httpsCallable('uploadFilesToNylas');

    const filesToSend = files.map(f => {
      const httpsReference = store.state.storage.refFromURL(f.downloadableURL);
      
      
      return {
        fireID: f.ID,
        bucket: httpsReference.bucket,
        filePath: httpsReference.fullPath,
        fileName: f.fileName,
        contentType: f.fileType || ListValues.getContentType(f.fileName),
      }
    });

    

    const data = {
      'access_token': this.selectEmailSettings.nylasAccessToken,
      files: filesToSend
    };
    const res = await uploadFilesToNylas(data);
    return res;
  }

  async reUploadToNylas() {
    // TODO dev mode
    //functions.useFunctionsEmulator("http://localhost:5001");
    const filesToSend = this.emailData.fileIDs;
    const uploadFilesToNylas = functions.httpsCallable('uploadFilesToNylas');
    const data = {
      'access_token': this.selectEmailSettings.nylasAccessToken,
      files: filesToSend
    };
    this.attachLoading = true;
    const res = await uploadFilesToNylas(data);
    const filesIds = res.data;
    (this.emailData.fileIDs as any) = [...filesIds];
    
    this.attachLoading = false;
    return res;
  }

  async removeFromFirestore(fileRemoved: any = null) {
    if (fileRemoved) {
      if (fileRemoved.ID) {
        this.deleteFileFromStorage(
          `email_temp_attachments/${fileRemoved.ID}`
        );
      }
    } else {
      const files = this.emailData.fileIDs.filter((f: any) => f.removeFromStorage);
      await asyncForEach(files, async (file) => {
        this.deleteFileFromStorage(
          `email_temp_attachments/${file.ID}`
        );
      });
      this.emailData.fileIDs = [];
    }
  }


  checkIfTemplateRequiresAttachment(): boolean {
    if(this.design && this.design['attachmentRequired']) {
      if(this.emailData && this.emailData.fileIDs.length) {
        console.log('email data', this.emailData);
        return false
      }
      return true
    }
    return false
  }

  sendEmail() {
    if (!this.selectEmailSettings) {
      alert('Please configure your email settings');
      return;
    }

    const requiresAttachment = this.checkIfTemplateRequiresAttachment()
    if(requiresAttachment) {
      const message: any = this.$t('email.messages.attachmentsRequired')
      this.$q.notify({color: "negative", message: message});
      return;
    }
    this.loading = true;

    // TODO dev mode
    //functions.useFunctionsEmulator("http://localhost:5001");

    const sendMessage = functions.httpsCallable('sendMessage');
    const attachFilesIds = (this.emailData.fileIDs as any).map(n => n.nylasID);
    let body = this.emailData.message;
    body = body.replace('style="min-height: 100vh;"', 'style=""')
    const options = {
      to: ListValues.emailReplace(this.emailData.to)
        .split(',')
        .map(el => {
          return {email: el.trim()};
        }).filter(e => e.email),
      cc: ListValues.emailReplace(this.emailData.cc)
        .split(',')
        .map(el => {
          return {email: el.trim()};
        }).filter(e => e.email),
      bcc: ListValues.emailReplace(this.emailData.bcc)
        .split(',')
        .map(el => {
          return {email: el.trim()};
        }).filter(e => e.email),

      subject: this.emailData.subject,
      body: body,
      // eslint-disable-next-line @typescript-eslint/camelcase
      files: attachFilesIds,
    };

    if (this.data.threadID && this.data.replyToMessageID) {
      options['replyToMessageId'] = this.data.replyToMessageID;
    }

    sendMessage({
      accessToken: this.selectEmailSettings.nylasAccessToken,
      options
    }).then((res: any) => {

      this.loading = false;
      this.attachLoading = false;
      this.loadingDesign = false;

      this.emailData.fileIDs = [];

      // do action after email send
      if (this.emailAction) {
        this.doSendEmailAction();
      }

      //TODO move it to the workOrder
      /*
        const firePath = "tenants/" + store.state.tenantID + "/modules" + "/workOrders" + "/records";
        db
          .collection(firePath)
          .doc(this.formData.ID)
          .update({ threadID: res.data.threadId, replyToMessageID: res.data.replyToMessageId })
          .then(() => 
          .catch(() => 
         */

      this.loading = false;
      this.removeFromFirestore();
      this.$emit("receiveResetShowForm");
    }).catch(error => {
      console.log(error);
      this.$store.dispatch('showAlertMessage', 'error');
      this.loading = false;
    });
  }

  doSendEmailAction() {
    const runAction = functions.httpsCallable('runAction');
    //TODO dev mode
    //functions.useEmulator('localhost', 5001);

    const action = {ID: this.emailAction.ID, type: 'FieldUpdate'};
    const tenantID = store.state.tenantID;
    const parameters = {} as any;
    parameters.ID = this.formData.data.ID;
    parameters.module = this.formData.module;
    const data = {ID: action.ID, type: action.type, tenantID, parameters};
    
    runAction(data).then(res => {
      
    }).catch(err => {
      console.log(err);
    });
  }

  getInputStyle(refName){
    /* eslint-disable */
    // @ts-ignore: Unreachable code error
    return this.$refs[refName]?.hasError ? '' : {'margin-bottom': '-20px'}
  }

  onCancelSelectedContactDialog(){
    this.initSelectedContact()
  }

  async onContactSelect(){
    const model = new AbModel();
    let emailsString = this.emailData[this.currentEmailField];
    await asyncForEach(this.selectedContacts, async (e) => {
      const contact = await model.getByID(e, this.moduleForEmail);
      const delim = emailsString ? ',' : '';
      if(!emailsString.includes(contact.email)){
      emailsString = emailsString + delim + contact.email;
      }
    });
    this.emailData[this.currentEmailField] = emailsString;
}

  initSelectedContact(){
    this.selectedContacts = [];
    this.showSelectContactDialog = false;
    this.currentEmailField = '';
  }

}
