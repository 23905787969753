

export default class ProductLinesTools {

  static summary(rawData, sField) {
    let subTotal = 0;
    let total = 0;
    rawData.forEach(d => {
      if (d && sField && d[sField]) {
        const val = d[sField];
        subTotal += val;
        total += val;
      }
    });
    const res = {subTotal, total};
    return res;
  }

}
