
import BaseExtend from "@/components/Base/Mixin/BaseExtend.vue";
import BaseModalForms from '@/components/Base/Mixin/BaseModalForms'
import FormListMixin from "@/components/Mixin/FormListMixin";
// import ListViewSetting from "@/components/Parts/ListViewSetting";
// import SummaryInfo from '@/components/Base/Parts/SummaryInfo'
import ProductLinesTools from "@/components/Mixin/ProductLinesTools";
import store from '@/store'
import db from "@/db";
import functions from "@/functions";
import {mapState, mapActions} from "vuex";
import ListValues from "@/components/Mixin/ListValues";
import {uid} from "quasar";
import TableMixin from "../Mixin/TableMixin";
import Utils from "@/components/utils/Utils"
import Dialog from "@/components/Parts/Dialog.vue";
import { AgGridVue } from "ag-grid-vue";
import AgGridTable from "./AgGrid/Mixin/TableMixin.ts"
import Table from "@/components/Table/Components/Table.vue"
// import RelatedFieldRenderer from "./AgGrid/Renderers/RelatedFieldRenderer.vue"
// import RelatedFieldEditor from "./AgGrid/Editors/RelatedFieldEditor.vue"
// import DecimalEditor from "./AgGrid/Editors/DecimalEditor.vue"
// import NumberEditor from "./AgGrid/Editors/NumberEditor.vue"
import {uuid} from 'vue-uuid';

export default {
  name: "BaseSectionProductLines",
  components: {
    BaseModalForms,
    // ListViewSetting,
    Dialog,
    // AgGridVue,
    Table
    // 'relatedFieldRenderer': RelatedFieldRenderer,
    // 'relatedFieldEditor': RelatedFieldEditor,
    // 'decimalEditor': DecimalEditor,
    // 'numberEditor': NumberEditor,
  },
  // TODO the mixin order make sense the FormListMixin column dragable is correct
  mixins: [ BaseExtend, TableMixin, FormListMixin ],
  props: {
    title: null,
    searchFilter: null,
    moduleSettings: null,
    openAsRelated: null,
    visibleDefaultColumns: null,
    defaultColumns: null,
    togleRefreshList: null,
    onRowClickOver: null,
    onRowDblClickOver: null,
    columnSortEnabled: null,
    selectedString: null,
    paginationLabel: null,
    defaultPagination: null,
    filteredDataSaved: null,
    cursorDataSaved: null,
    isPlanBoardMode: null,
    showPlanboadrHeader: null,
  },
  data() {
    return {
      filterData: {},
      pagination: {
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 10,
        sortBy: "changedAt",
        descending: true
      },
      productLinesData: [],
      filteredList: [],
      timeRecords: [],
      purchaseRecords: [],
      showRelatedModalForm: false,
      timeRecordDeleteModal: false,
      sectionsShowedLoaded: false,
      clickedRelatedField: false,
      modalRelatedFormID: null,
      relatedModuleModal: '',
      relatedFieldRendererKey: null,
      agGridTableKey: '',
      agColumns: [],
      gridApi: null,
      columnApi: null,
      rowData: null,
      inputRow: {},
      pinnedBottomRowData: [this.inputRow],
      agGridComponents: {},
      rowSelection: 'single',
      defaultColDef: {
        flex: 1,
        editable: true,
        valueFormatter: (params) =>
          this.isEmptyPinnedCell(params) ? this.createPinnedCellPlaceholder(params) : undefined,
      },
    };
  },
  mounted () {  
    this.subscribeToLineModule();
    this.relatedFieldRendererKey = uuid.v1()
    this.agGridTableKey = uuid.v1()
  },
  beforeDestroy() {
    this.unSubscribeModule({ docModule: this.getProductModuleStoreName() });
  },
  methods: {
    ...mapActions('product', ['subscribeModule', 'unSubscribeModule']),
    onGridReady(params) {
      this.agColumns = []
      const definedColums = []
      for(const column of this.columns) {
        const col = AgGridTable.getColumn(column, this.formData, this.section, this.lineSection)
        definedColums.push(col.field)
        this.agColumns.push(col)
      }
      for(const column of this.allColumnsForSelect) {
        if(!definedColums.includes(column.name)) {
          const module = store.state.dynamicModules[this.lineSection.lineType]
          const fields = module.fields
          column.fieldType = fields[column.name].type
          const col = AgGridTable.getColumn(column, this.formData, this.section, this.lineSection)
          col.hide = true
          this.agColumns.push(col)
          definedColums.push(col.field)
        }
      }
    },
    onSelectionChanged(params) {
      setTimeout(() => {
        if(!this.doubleClicked) {
          if(!store.state.relatedFieldClicked) {
            params.api.stopEditing()
            const selectedRow = params.api.getSelectedRows();
            this.onRowClick('', selectedRow[0])
          }
          store.state.relatedFieldClicked = false
        }
      }, 250);
    },
    cellDoubleClicked(params) {
      this.doubleClicked = true
      if(params.rowPinned !== "bottom") {
        params.api.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: params.colDef.field
        })
      }
    },
    stoppedEditingCell(params) {
      if(params.rowPinned !== "bottom" && params.newValue !== undefined) {
        const data = {
          [params.column.colId]: params.newValue
        }
        this.updateProductLine(data, this.formData.dynamicSections[this.lineSection.name].lineType, params.data.ID)
      }
      this.doubleClicked = false
    },
    onCellEditingStopped(params) {
      if (this.isPinnedRowDataCompleted(params)) {
        // save data
        this.createProductLine(params.data, this.formData.dynamicSections[this.lineSection.name].lineType)
        //reset pinned row
        this.inputRow = {};
        params.api.setPinnedBottomRowData([this.inputRow]);
      }
    },
    isPinnedRowDataCompleted(params) {
      if (params.rowPinned !== 'bottom') return;
      return this.agColumns.every((def) => params.data[def.field]);
    },
    isEmptyPinnedCell(params) {
      return (
        (params.node.rowPinned === 'bottom' && params.value == null) ||
        (params.node.rowPinned === 'bottom' && params.value === '')
      );
    },
    createPinnedCellPlaceholder({ colDef }) {
      return colDef.headerName[0].toUpperCase() + colDef.headerName.slice(1) + '...';
    },
    async createProductLine(data, module) {
      const docID = db.collection("tenants").doc().id
      await db.collection(`tenants/${store.state.tenantID}/modules/${module}/records/`).doc(docID).set(data, {merge: true}).then((res) => {
        this.$q.notify({ message: this.$t('common.messages.recordCreated'), color: 'positive'})
      })
    },
    async updateProductLine(data, module, docID) {
      await db.collection(`tenants/${store.state.tenantID}/modules/${module}/records/`).doc(docID).set(data, {merge: true}).then((res) => {
        this.$q.notify({ message: this.$t('common.messages.recordChanged'), color: 'positive'})
      })
    },
    getSelectedRows() {
        const selectedNodes = this.gridApi.getSelectedNodes();
        const selectedData = selectedNodes.map( node => node.data );
        const selectedDataStringPresentation = selectedData.map( node => `${node.make} ${node.model}`).join(', ');
        alert(`Selected nodes: ${selectedDataStringPresentation}`);
    },
    keyUid() {
      return uid();
    },
    openDeleteModal() {
      this.timeRecords = []
      this.purchaseRecords = []
      for(const record of this.selected) {
        if(record.timeRecord) {
          this.timeRecords.push(record)
        }
        if(record.purchaseRecord) {
          this.purchaseRecords.push(record)
        }
      }
      if(this.timeRecords.length || this.purchaseRecords.length) {
        this.timeRecordDeleteModal = true
      }
      if(!this.timeRecordDeleteModal) {
        this.deleteFormsModal = true
      }
    },
    customSort(rows, sortBy, descending) {
      return ListValues.customSort(rows, sortBy, descending);
    },
    subscribeToLineModule() {
      const filter = {record: this.formData.data.ID, module: this.formData.module};
      this.subscribeModule({ docModule: this.getProductModuleStoreName(), module: this.lineModule, filter });
    },
    saveDataToFormBuffer(dataToSave) {
      const lineSection = this.lineSection.name;
      if (!dataToSave.ID) {
        dataToSave.ID = uid();
      }
      if (!this.formData.bufferedDataToSave[lineSection]) {
        this.$set(this.formData.bufferedDataToSave, lineSection, {});
      }
      this.$set(this.formData.bufferedDataToSave[lineSection], dataToSave.ID, dataToSave);
    },
    localDeleteListItems(selected) {
      if (!this.bufferSave) {
        return false;
      }
      const selIDs = selected.map(s =>s.ID);
      const bData = this.getBufferData();
      const bDataKeys = Object.keys(bData);
      for (const ind in bDataKeys) {
        const id = bDataKeys[ind];
        if (selIDs.includes(id)) {
          this.$delete(bData, id);
        }
      }
      return true;
    },
    localOnRowClick(evt, row) {
      if (!this.bufferSave) {
        return false;
      }
      const ID = row.ID;
      this.modalFormObjData = {...this.getBufferDataByID(ID)};
    },
    setBufferData(newData) {
      const lineSection = this.lineSection.name;
      this.formData.bufferedDataToSave[lineSection] = newData;
    }, getBufferData() {
      const lineSection = this.lineSection.name;
      const bufData = this.formData.bufferedDataToSave && this.formData.bufferedDataToSave[lineSection] ?
        this.formData.bufferedDataToSave[lineSection] : {};
      return bufData;
    },
    getBufferDataByID(ID) {
      return this.getBufferData()[ID];
    },
    onRequest () {
      // no doing
    },
    getTotal(column) {
      if (this.visibleFormListColumns.indexOf(column) === 0) {
        return this.$t('productLines.headers.total');
      } else {
        if(column != "marginPercentage") {
          const totalValue = this.columnTotals[column] ? ListValues.toFieldFormat(this.columnTotals[column], this.dynFields[column]) : '';
          return totalValue;
        } else {
          const subTotalSales = this.columnTotals["subtotalSales"] / 10000
          const subTotalPurchase = this.columnTotals["subtotalPurchase"] / 10000
          let totalValue = 0
          if(subTotalSales !== 0 && subTotalPurchase !== 0) {
            const subTotalValue = subTotalSales - subTotalPurchase
            totalValue = subTotalValue/subTotalSales * 100
          }
          return totalValue.toFixed(2).toString().replace(".",",") + "%"
        }
      }
    },
    getProductModuleStoreName() {
      const mName = this.formData.module + '_' + this.name + '_' + this.formData.data.ID;
      return mName;
    },
    getQueryFilter() {
      const relatedID = this.formData.data.ID;
      const filter = {
        relatedQuery: {record: relatedID}
      };
      return filter;
    },
    getListSettings() {
      return {};
    },
    doCustomButtonAction(buttonAction) {
      const actions = buttonAction.actions.sort((a, b) => {
        return a.order - b.order;
      });
      actions.forEach(action => {
        const runAction = functions.httpsCallable('runAction');
        //TODO dev mode
        //functions.useEmulator('localhost', 5001);

        const tenantID = store.state.tenantID;
        const parameters = {};
        parameters.ID = this.formData.data.ID;
        parameters.module = this.formData.module;
        const data = {ID: action.ID, tenantID, type: action.type, parameters};
        runAction(data).then(() =>{
          this.refreshList();
          
        })
      });
    },
    saveTheForm(needCallbackAlert = true) {
      this.$emit('saveTheForm', needCallbackAlert);
    }
  },
  computed: {
    ...mapState("product", ["productModules"]),
    // RBAC
    isModuleShow() {
      return this.formData.guard.checkModule(this.lineType);
    },
    isFieldWiseDisabled() {
      const isAddDisabled = !this.formData.guard.check('create', this.lineType);
      if(isAddDisabled) return true;
      const isSectionReadOnly = this.isSectionReadonly 
      if(isSectionReadOnly) return true;
      const fieldWiseControl = this.fieldWiseControl('DISABLED')
      if(fieldWiseControl) return true;
      return false
    },
    isAddShow() {
      return !this.isSectionReadonly && !this.formData.guard.hide('create', this.lineType);
    },
    isDeleteShow() {
      return !this.isSectionReadonly && !this.formData.guard.hide('delete', this.lineType);
    },
    isAddDisabled() {
      return !this.formData.guard.check('create', this.lineType);
    },
    isDeleteDisabled() {
      return !this.formData.guard.check('delete', this.lineType);
    },
    rawData() {
      if (this.formData.data.ID) {
        const sm = this.getProductModuleStoreName();
        return this.productModules[sm] || [];
      } else {
        const bufData = this.getBufferData();
        return Object.values(bufData);
      }
    },
    bufferSave() {
      return this.$store.state.PORTAL && !this.formData.data.ID;
    },
    productData() {
      const searchFilters =  {
        'text': function(value, searchValue){ return value ? value.toLowerCase().includes(searchValue.toLowerCase()) : null},
        'largeText': function(value, searchValue){ return value ? value.toLowerCase().includes(searchValue.toLowerCase()) : null},
        'dropdown': function(value, searchValue){ return searchValue.includes(value) },
        'checkbox': function(value, searchValue){ if(searchValue == "-"){return value == ""}else{return value == searchValue} },
        'relatedField': function(value, searchValue){ return value ? value.toLowerCase().includes(searchValue.toLowerCase()) : null},
        'decimal': function(value, searchValue){ return value ? value.startsWith(searchValue) : null},
        'percentage': function(value, searchValue){ return value.slice(0, -1).startsWith(searchValue)},
        'datetime': function(value, searchValue){ return value ? value.startsWith(searchValue) : null},
        'calculatedField': function(value, searchValue){
          if(value != undefined || value != "") {
            if(value.includes("€")) {
              return value.slice(1).startsWith(searchValue)
            } else if(value.includes("%")) {
              return value.startsWith(searchValue)
            }
          }
        },
        'currency': function(value, searchValue){ return value.slice(1).startsWith(searchValue)},
      }
      const filteredData = Utils.clientSideFiltering(this.rawData, this.formData.dynamicSections[this.lineSection.name].filter)
      const productLines = ListValues.transform(filteredData.map(r => { return {...r}}), this.dynFields, true);
      let filteredList = productLines
      const fields = this.dynamicFields;
      for(const productLine in productLines) {
        if(productLines[productLine].marginPercentage == "-Infinity") {
          productLines[productLine].marginPercentage = "∞"
        }
        if(productLines[productLine].marginPercentage_sort == "-Infinity") {
          productLines[productLine].marginPercentage_sort = "∞"
        }
      }
      for(const i in this.filterData) {
        if(this.filterData[i] === "" || this.filterData[i].length === 0){
          continue
        }
          const filteredRows = []
          for(const productLine in filteredList) {
            const fieldType = fields[i].type
            const value = filteredList[productLine][i];
            const searchValue = this.filterData[i]
            if(searchFilters[fieldType](value, searchValue)){
              filteredRows.push(filteredList[productLine])
            }
          }
          filteredList = filteredRows
      }
      return filteredList
    },
    summary() {
      const sField = this.section.summaryField;
      return ProductLinesTools.summary(this.rawData, sField);
    },
    columnTotals() {
      const dFields = Object.keys(this.dynFields).filter(k => ListValues.isDigitalType(this.dynFields[k]));
      const totals = {};
      let rawData = this.rawData;
      if (this.productData.length) {
        const filteredIDs = this.productData.map(s => s.ID);
        rawData = this.rawData.filter(r => filteredIDs.includes(r.ID));
        if (this.selected.length) {
          const selectedIDs = this.selected.map(s => s.ID);
          rawData = rawData.filter(r => selectedIDs.includes(r.ID));
        }
      } else {
        rawData = []
      }
      this.visibleFormListColumns.forEach(v => {
        if (dFields.includes(v)) {
          totals[v] = ProductLinesTools.summary(rawData, v).total;
        }
      });
      return totals;
    },
    showSummary() {
      return this.section && this.section.showSummary;
    },
    summaryField() {
      return this.section && this.section.summaryField;
    },
    lineModule() {
      return this.lineType;
    },
    lineSection() {
      return this.section;
    },
    columns() {
      const cols = [];
      const fields = this.dynamicFields;
      for(const i in this.visibleFormListColumns) {
        const column = this.visibleFormListColumns[i];
        if (fields[column]) {
          const newCol = {
            name: column,
            align: this.align,
            label: fields[column] && fields[column].label ? fields[column].label : column,
            order: fields[column] && fields[column].order ? fields[column].order : 0,
            field: column,
            sortable: true,
            fieldType: fields[column].type
          };
          if (!fields[column].hidden) {
            cols.push(newCol);
          }
        }
      }
      return cols;
    },
    dynFields() {
      return this.dynamicFields;
    },
    dynamicFields() {
      return store.state.allModules && store.state.allModules[this.lineModule] ? store.state.allModules[this.lineModule].fields : {};
    },
    customButtonsComps() {
      return this.customButtons || [];
    },
    currentModule() {
      return this.lineModule;
    },
    dropDownValues() {
      const mData = store.state.dynamicModules[this.currentModule];
      return mData ? mData.dropDownValues : {};
    },
  },
  watch: {
    'formData.data.ID': {
      handler() {
        this.subscribeToLineModule();
      },
      deep: true
    },
    filterData: {
      handler() {
        this.productData
      },
      deep: true
    },
    columns: {
      handler(value) {
        if(value.length != this.agColumns.length) {
          this.onGridReady()
        }
      },
      deep: true
    }
  },
};
