
import BaseExtend from "@/components/Base/Mixin/BaseExtend";
import BaseModalForms from '@/components/Base/Mixin/BaseModalForms'
import { mapActions } from 'vuex'
import ListValues from '@/components/Mixin/ListValues'
import FormListMixin from "@/components/Mixin/FormListMixin";
import ListViewSetting from "@/components/Parts/ListViewSetting";
import Dialog from "@/components/Parts/Dialog.vue";
import Table from "@/components/Table/Components/Table.vue"
import Vue from 'vue'
import { ObserveVisibility } from 'vue-observe-visibility'
import debounce from "lodash/debounce";
Vue.directive('observe-visibility', ObserveVisibility)

export default {
  name: "BaseSectionRelatedRecords",
  // ListViewSetting,
  components: { BaseModalForms,  Dialog, Table },
  mixins: [ BaseExtend, FormListMixin ],
  data() {
    return {
      searchAfterByPage: {},
      localFormListPagination: {
        sortBy: "changedAt",
        descending: true,
        rowsNumber: 0,
      },
      sectionsShowedLoaded: false,
      showRelatedModalForm: false,
      timeRecordDeleteModal: false,
      showTotal: false,
      modalRelatedFormID: null,
      relatedModuleModal: '',
      columnOptionsSelected: {},
      filterData: {},
      uuid: '',
      columnOptions: {},
      filterFlags: {},
      columnTotals: {},
      timeRecords: [],
      purchaseRecords: [],
      tableIsInViewPort: false,
    };
  },
  methods: {
    ...mapActions("query", ["getRecords"]),
    filterChanged() {
      this.doFilteredRequest();
    },
    getQueryFilter() {
      const relatedID = this.formData.data.ID;
      const filter = {
        relatedQuery: {[this.relatedByField + ".ID"]: relatedID}
      };
      return filter;
    },
    isTableInViewPort(inViewPort){
      this.tableIsInViewPort = inViewPort
    },
    visibilityChanged(isVisible) {
      if (isVisible) {
        if (!this.sectionsShowedLoaded) {
          this.sectionsShowedLoaded = true;
          this.onRequest();
        }
      }
    },
    openDeleteModal() {
      this.timeRecords = []
      this.purchaseRecords = []
      for(const record of this.selected) {
        if(record.timeRecord) {
          this.timeRecords.push(record)
        }
        if(record.purchaseRecord) {
          this.purchaseRecords.push(record)
        }
      }
      if(this.timeRecords.length || this.purchaseRecords.length) {
        this.timeRecordDeleteModal = true
      }
      if(!this.timeRecordDeleteModal) {
        this.deleteFormsModal = true
      }
    },
    calculateTotal() {
      this.onRequest(null, true)
      this.showTotal = true
    },
    onRequest(props, getTotals) {
      if (!this.sectionsShowedLoaded) {
        return;
      }
      const {page, rowsPerPage, sortBy, descending} = props ? props.pagination : this.localFormListPagination;

      this.loading = true;
      let nextCursor = null;
      if (page === 1) {
        this.searchAfterByPage = {};
      } else {
        nextCursor = this.searchAfterByPage[page - 1];
      }

      const filterTable = {columnOptionsSelected: this.columnOptionsSelected, filterData: this.filterData};
      const relatedFilter = this.getQueryFilter();

      const filter = { ...filterTable, ...relatedFilter};
      if(this.formData.dynamicSections[this.section.name].filter) {
        filter['optionalFilter'] = this.formData.dynamicSections[this.section.name].filter
      }

      let param = {
        filter,
        module: this.currentModule,
        cursor: nextCursor,
        size: rowsPerPage,
        sortBy: sortBy,
        descending: descending,
      };

      if(getTotals) {
        const totalFields = this.getTotalFields();
        param = {
          filter,
          module: this.currentModule,
          cursor: nextCursor,
          size: rowsPerPage,
          sortBy: sortBy,
          descending: descending,
          totalFields
        };
      }
      this.getRecords(param).then((res) => {
        if (res.count) {
          this.localFormListPagination.rowsNumber = res.count;
        }
        this.queryRequestData = ListValues.transform(res.docs, this.dynFields);
        this.searchAfterByPage[page] = res.searchAfter;

        this.localFormListPagination.page = page;
        this.localFormListPagination.rowsPerPage = rowsPerPage;
        this.localFormListPagination.sortBy = sortBy;
        this.localFormListPagination.descending = descending;
        this.setFormListPagination();
        if (res.aggs) {
          this.columnTotals = res.aggs;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;

      });
    },
    getTotalFields() {
      const dFields = this.visibleFormListColumns.filter(k => ListValues.isDigitalType(this.dynFields[k]));
      return dFields;
    },
    getTotal(column) {
      if (this.visibleFormListColumns.indexOf(column) === 0) {
        if(this.showTotal) {
          return this.$t('productLines.headers.total');
        } else {
          return this.$t('common.buttons.calculateTotal');
        }
      } else {
        const totalValue = this.columnTotals[column] ? ListValues.toFieldFormat(this.columnTotals[column], this.dynFields[column]) : '';
        return totalValue;
      }
    },
    onListRefreshForTotals() {
      this.onRequest();
    },
    // QGrtid
    setColumnFilterOptions(cols) {
      const off = true;
      if (off) {
        const columnOptions = {};
        cols.filter((item) => {
          columnOptions[item.field] = [];
          this.$set(this.columnOptionsSelected, item.field, []);
          this.filterFlags[item.field] = false;
        });
        // get options from the dropdown values
        cols.filter((column) => {
          const dValues = this.dropDownValues[column.field];
          if (dValues && column.filterType === 'select') {
            dValues.filter(d => {
              columnOptions[column.field].push({
                label: d.toString(),
                value: d.toString().replace(/_/g, '_')
              })
            });
          }
        });
        cols.filter((column) => {

          if (column.filterType === 'select') {
            if (column.type === 'checkbox') {
              columnOptions[column.field].push({label: '✓', 'value': true});
              columnOptions[column.field].push({label: '-', 'value': false});
            } else {
              columnOptions[column.field].push({label: '-', 'value': ''});
            }
            columnOptions[column.field] = [...new Map(columnOptions[column.field].map(item =>
              [item['value'], item])).values()];
          }
        });
        this.columnOptions = columnOptions;
        return columnOptions;
      } else {
        this.columnOptions = [];
        return [];
      }
    },
  },
  computed: {
    isFieldWiseDisabled() {
      const isAddDisabled = !this.formData.guard.check('create', this.module);
      if(isAddDisabled) return true;
      const isSectionReadOnly = this.isSectionReadonly 
      if(isSectionReadOnly) return true;
      const fieldWiseControl = this.fieldWiseControl('DISABLED')
      if(fieldWiseControl) return true;
      return false
    },
    showSummary() {
      return true;
    },
    currentModule() {
      return this.relatedModule;
    },
    isShowMultiSelection() {
      return this.openAsRelated ? 'none' : 'multiple';
    },
    isShowColumnFilter() {
      return !this.openAsRelated;
    },
    isShowHeaderFilter() {
      return false;
    },
  },
  mounted() {
    this.setColumnFilterOptions(this.columns);
    this.doFilteredRequest = debounce(this.onRequest, 500);
  },
  beforeDestroy() {
    this.doFilteredRequest.cancel()
    this.loading = false;
  },
};
