
import {Component, Vue} from "vue-property-decorator";
  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';
  import {State} from "vuex-class";

  @Component({
    components: {
      vueDropzone: vue2Dropzone,
    },
  })
  export default class Dropzone extends Vue {
    @State("settings/userEmailSettings") userEmailSettings;
    dropzoneOptions = {
      url: '#',
      thumbnailWidth: 150,
      maxFilesize: 25,
      uploadMultiple: true,
      addRemoveLinks: true,
      autoProcessQueue: false,
      dictDefaultMessage:
          `<div class="column items-center">
             <div><i class="material-icons text-h5 text-primary-shade">note_add</i><div/>
             <div class="text-body1 text-primary">${this.$t("dropzone.headers.dropFilesRow2")}</div>
             <div class="text-caption text-primary-shade-light">${this.$t("dropzone.headers.dropFilesRow3")}</div>
           </div>`,
      dictRemoveFile: this.$t("dropzone.headers.deleteFile"),
      dictFileTooBig: this.$t("common.messages.attachmentOverSize")
    };
    filesSize = 0;
    maxSize = 10 * 1000000; //Mb
    get sizeOver() {
      return this.filesSize >= this.maxSize;
    }
    get sizeLabel() {
      const mb = this.filesSize / 1000000;
      return mb.toFixed(1) + ' MB'
    }
    async addedFiles(files) {
      Object.keys(files).forEach(f => {
        this.filesSize += files[f].size;
      });
      
      this.$emit("addFiles", { files, sizeOver: this.sizeOver });
    }
    addToDropZone(addFile, url) {
      
      this.filesSize += addFile.size;
      // eslint-disable-next-line
      // @ts-ignore: Unreachable code error
      this.$refs.myVueDropzone.manuallyAddFile({ ...addFile}, url);
      this.$emit("addFiles", { addFile, sizeOver: this.sizeOver });
    }
    addToDropZonePDF(pdf) {
      
      const url = 'data:application/pdf;base64,' + pdf.base64PDF;
      pdf.size = url.length;
      pdf.filesSize = pdf.size;
      //delete pdf.base64PDF;
      this.filesSize += pdf.size;
      // eslint-disable-next-line
      // @ts-ignore: Unreachable code error
      this.$refs.myVueDropzone.manuallyAddFile(pdf, url);
      this.$emit("addFiles", { files: [pdf], sizeOver: this.sizeOver });
    }
    removedFile(removedFile) {
      this.filesSize -= removedFile.size;
      this.$emit("removedFile", { removedFile, sizeOver: this.sizeOver });
    }
  }
