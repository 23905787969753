
  import {date} from "quasar";
  import { mapActions, mapState } from "vuex";
  import axios from "axios";
  import store from "./../../store";
  import draggable from 'vuedraggable';
  import functions from '../../functions';
  import { Component, Prop, Vue, Watch } from "vue-property-decorator";
  import TagsBlock from "./TagsBlock.vue";
  import PreviewFileDialog from "./PreviewFileDialog.vue";

  @Component({
    components: {draggable, TagsBlock, PreviewFileDialog},
  })

  export default class AttachmentsTable extends Vue {
    @Prop() attachments
    @Prop() readonly
    @Prop() formData
    @Prop() preTags

    editableItem: any = {};
    openEditNameModal = false;
    selected: any = [];
    selectedAttachments: any = []
    templates = [];
    mergeModal = false;
    wrongExtensionModal = false;
    wrongExtensions: any = [];
    fileName = "";
    validMergePDF_License = false
    sortedList: any = []
    showFileModal = false
    file_URL = ""
    downloadableAttachment: any = []
    disabledDeleteButton = false

    mounted() {
      mapActions(["saveWorkOrdersCounter"])
      this.setSelectedAttachmentFalse()
      if(store.state && store.state.licenses && store.state.licenses.mergePDF && store.state.licenses.mergePDF.active) {
        this.validMergePDF_License = true
      }
      this.sortedList = [...this.attachments]
    }

    @Watch('attachments')
    setAttachmentInSortedList() {
      this.sortedList = [...this.attachments]
      
      this.setSelectedAttachmentFalse()
    }

    setSelectedAttachmentFalse() {
      this.selected = []
      this.selectedAttachments = [];
      for(const attachment in this.attachments) {
          const value = this.attachments[attachment].ID
          this.selected[value] = false
      }
    }

    clearAll() {
      this.setSelectedAttachmentFalse();
      this.selectedAttachments = [];
      this.wrongExtensions = []
    }

    filterSelectedAttachments() {
      const executableExtensions = [
        'cbc', 'cbr', 'cbz', 'chm', 'djvu', 'epub', 'lit', 'lrf', 'mobi', 'pml', 'prc', 'snb', 'tcr',
        'csv', 'doc', 'docx', 'dot', 'dotx', 'log', 'mpp', 'mpt', 'pot', 'potx', 'pps', 'ppsx',
        'key', 'numbers', 'pages', 'dwf', 'dwfx', 'dwg', 'dwgx', 'dxf', 'azv', 'azv1', 'azv3', 'azv4',
        'mdi', 'png', 'tif', 'tiff', 'webp', '123', '12m', 'lwp', 'mwp', 'sam', 'wk1', 'wk2', 'wk3',
        'otg', 'oth', 'otp', 'ots', 'pdf', 'pot', 'pps', 'ppt', 'pptx', 'pxl', 'sgl', 'smf', 'srw',
        'ppt', 'pptx', 'pub', 'rtf', 'txt', 'vdx', 'vsd', 'vsdx', 'vst', 'vstx', 'wpd', 'wps', 'wri',
        'prn', 'ps', 'oxps', 'snp', 'xps', 'htm', 'html', 'web',
        'stc', 'sti', 'stw', 'sxc', 'sxg', 'sxi', 'sxm', 'sxw', 'vor', 'wv2', 'xls', 'xlsx' , 'eps',
        'tpz', 'eml', 'mbx', 'msg', 'oft', 'bmp', 'fax', 'gif', 'heic', 'ico', 'images', 'jpeg', 'jpg',
        'xls', 'xlsb', 'xlsx', 'xlt', 'xltx', 'mml', 'odc', 'odf', 'odg', 'odi', 'odm', 'odp', 'ods'
      ]
      this.wrongExtensions = []
      const attachments: any = []
      for(const selectedAttachment in this.selectedAttachments){
        const ext = this.selectedAttachments[selectedAttachment].fileName.split(".")
        if(executableExtensions.includes(ext[ext.length - 1].toLowerCase())){
          attachments.push(this.selectedAttachments[selectedAttachment])
        } else {
          this.wrongExtensions.push(this.selectedAttachments[selectedAttachment])
          this.selected[this.selectedAttachments[selectedAttachment].ID] = false
        }
      }
      this.selectedAttachments = []
      for(const a in this.sortedList) {
        if(attachments.includes(this.sortedList[a])) {
          this.selectedAttachments.push(this.sortedList[a])
          const splittedFileName = this.selectedAttachments[0].fileName.split(".")
          this.fileName = splittedFileName[0] + ' ' + this.$t('attachments.fields.merged')
        }
      }
      if(attachments.length > 0) {
        this.mergeModal = true
      } else {
        this.wrongExtensionModal = true
      }
    }

    async getModuleTemplates(): Promise<void> {
      this.disabledDeleteButton = true
      this.$q.notify({ message: `${this.$t('common.messages.mergeStarted')}`, color: 'positive'})

      // TODO dev mode
      // functions.useFunctionsEmulator('http://localhost:5001');
      //

      const generatePDF = functions.httpsCallable('MergePDF');

      const options = {
        tenant: store.state.tenantID,
        module: this.formData.module,
        record: this.formData.data.ID,
        attachments: this.selectedAttachments,
        fileName: this.fileName
      };

      await generatePDF(options).then((res: any) => {
        const data = res.data;
        this.templates = data;
        if(data == false) {
          this.$q.notify({ message: `${this.$t('common.messages.mergeFailed')}`, color: 'negative', icon: 'warning'})

        } else {
          this.$q.notify({ message: `${this.$t('common.messages.mergeCompleted')}`, color: 'positive'})
        }
        this.disabledDeleteButton = false
      }).catch(() => {
        this.$q.notify({ message: `${this.$t('common.messages.mergeFailed')}`, color: 'negative', icon: 'warning'})
        this.disabledDeleteButton = false
        this.templates = [];
      });
      this.sortedList = [...this.attachments]
      this.clearAll()
    }

    changeSelectedAttachmentList(value, attachment){
      if(value) {
        this.selectedAttachments.push(attachment)
      } else if (!value){
        const index = this.selectedAttachments.indexOf(attachment)
        this.selectedAttachments.splice(index, 1)
      }
    }

    saveSortedList() {
      
      this.saveRecord();
    }

    saveRecord() {
      
      this.$emit("editAttachments", {data : this.sortedList})
    }

    removeAttachments() {
      for(const selectedAttachment in this.selectedAttachments) {
        const index = this.sortedList.indexOf(this.selectedAttachments[selectedAttachment])
        this.sortedList.splice(index, 1)
      }
      this.setSelectedAttachmentFalse()
      this.saveRecord()
      this.clearAll()
    }
    toggleEditNameModal() {
      if (this.openEditNameModal) {
        this.editName();
      }
      this.openEditNameModal = !this.openEditNameModal;
    }

    editItem(item) {
      this.editableItem = item;
      
      this.editableItem.tags = Array.isArray(item.tags) ? item.tags : [];
      this.toggleEditNameModal()
    }
    editTags(item) {
      this.editableItem = item;
      this.editableItem.tags = Array.isArray(item.tags) ? item.tags : [];
    }
    fileTags(file) {
      return (file && file.tags && Array.isArray(file.tags)) ? file.tags : [];
    }
    editName() {
      const { attachments, editableItem } = this;
      
      attachments.forEach((attachment) => {
        if (editableItem.ID === attachment.ID) {
          attachment.tags = editableItem.tags.map(t => t.trim());
          

          store.dispatch('settings/saveUsedTags',{
              module: this.formData.module,
              tags: attachment.tags
          });

          return editableItem;
        }
      });

      
      this.$emit("editAttachments", { data: attachments });
    }

    removeItem(item) {
      this.$emit("removeAttachment", { data: item });
    }

    toDateTimeValue(val) {
      if (val && typeof val.toDate === "function") {
        return date.formatDate(val.toDate(), store.state.dateTimeMask);
      }
      if (val && val instanceof Date) {
        return date.formatDate(val, store.state.dateTimeMask);
      }
      return val;
    }

    getSize (val) {
      return val >= 1024 ? Math.floor(val / 1024) + ' KB' : val + ' B'
    }
    computed() {
      mapState("subsribe", ["workOrdersCounter"])
    }


    getFontAwesomeIconFromMIME(mimeType) {
      // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
      const icon_classes = {
        // Media
        'image': 'far fa-image',
        'audio': 'far fa-file-audio',
        'video': 'far fa-file-video',
        // Documents
        'application/pdf': 'far fa-file-pdf',
        'application/msword': 'far fa-file-word',
        'application/vnd.ms-word': 'far fa-file-word',
        'application/vnd.oasis.opendocument.text': 'far fa-file-word',
        'application/vnd.openxmlformats-officedocument.wordprocessingml': 'far fa-file-word',
        'application/vnd.ms-excel': 'far fa-file-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'far fa-file-excel',
        'application/vnd.oasis.opendocument.spreadsheet': 'far fa-file-excel',
        'application/vnd.ms-powerpoint': 'far fa-file-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml': 'far fa-file-powerpoint',
        'application/vnd.oasis.opendocument.presentation': 'far fa-file-powerpoint',
        'text/plain': 'far fa-file-alt',
        'text/html': 'far fa-file-code',
        'application/json': 'far fa-file-code',
        // Archives
        'application/gzip': 'far fa-file-archive',
        'application/zip': 'far fa-file-archive'
      };
      

      const icon = icon_classes[mimeType] === undefined ? "far fa-file" : icon_classes[mimeType]
      return icon
    }

    downloadAttachment(file) {
      const executableExtensions = [
        'cbc', 'cbr', 'cbz', 'chm', 'djvu', 'epub', 'lit', 'lrf', 'mobi', 'pml', 'prc', 'snb', 'tcr',
        'csv', 'doc', 'docx', 'dot', 'dotx', 'log', 'mpp', 'mpt', 'pot', 'potx', 'pps', 'ppsx',
        'key', 'numbers', 'pages', 'dwf', 'dwfx', 'dwg', 'dwgx', 'dxf', 'azv', 'azv1', 'azv3', 'azv4',
        'mdi', 'png', 'tif', 'tiff', 'webp', '123', '12m', 'lwp', 'mwp', 'sam', 'wk1', 'wk2', 'wk3',
        'otg', 'oth', 'otp', 'ots', 'pdf', 'pot', 'pps', 'ppt', 'pptx', 'pxl', 'sgl', 'smf', 'srw',
        'ppt', 'pptx', 'pub', 'rtf', 'txt', 'vdx', 'vsd', 'vsdx', 'vst', 'vstx', 'wpd', 'wps', 'wri',
        'prn', 'ps', 'oxps', 'snp', 'xps', 'htm', 'html', 'web',
        'stc', 'sti', 'stw', 'sxc', 'sxg', 'sxi', 'sxm', 'sxw', 'vor', 'wv2', 'xls', 'xlsx' , 'eps',
        'tpz', 'eml', 'mbx', 'msg', 'oft', 'bmp', 'fax', 'gif', 'heic', 'ico', 'images', 'jpeg', 'jpg',
        'xls', 'xlsb', 'xlsx', 'xlt', 'xltx', 'mml', 'odc', 'odf', 'odg', 'odi', 'odm', 'odp', 'ods'
      ]
      axios({
        url: file.downloadableURL,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        let fileName = file.fileName
        let fileType = file.fileType

        if (executableExtensions.includes(file.fileName.split(".")[file.fileName.split(".").length - 1].toLowerCase())) {          
          fileType = file.fileName.split(".")[file.fileName.split(".").length - 1]
          const fileNameAttributes = file.fileName.split(".")
          fileNameAttributes.pop();
          fileName = fileNameAttributes.join(".")
        }
        fileLink.setAttribute('download', `${fileName}.${fileType}`);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    }

    showFile(attachment) {
      this.downloadableAttachment = attachment      
      this.showFileModal = true
    }

    closeShowFileModal() {
      this.file_URL = ""
      this.showFileModal = false
      this.downloadableAttachment = []
    }
  }
