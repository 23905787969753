import store from "@/store"

const AgGridTable = {
    getColumn(column, formData, section, lineSection) {
        if (column.fieldType === 'relatedField') {
            return this.getRelatedColumn(column, formData, section, lineSection)
        } else if (column.fieldType === 'dropdown') {
            return this.getDropDownColumn(column, lineSection)
        } else if (column.fieldType === 'decimal') {
            return this.getDecimalColumn(column)
        } else if (column.fieldType === 'checkbox') {
            return this.getCheckboxColumn(column)
        } else if (column.fieldType === 'number') {
            return this.getNumberColumn(column)
        } else {

            return {
                field: column.name,
                headerName: column.label,
                sortable: true,
                editable: true,
                resizable: true,
                floatingFilter: true,
                cellStyle: {color: 'white'},
                aggFunc: 'sum',
                filter: 'agTextColumnFilter'
            }
        }
    },
    getNumberColumn(column) {
        const numberColumn = {
            field: column.name,
            headerName: column.label,
            sortable: true,
            editable: true,
            resizable: true,
            cellEditor: 'numberEditor',
            floatingFilter: true,
            cellStyle: {color: 'white'},
            aggFunc: 'sum',
            filter: 'agTextColumnFilter'
        }
        return numberColumn
    },
    getCheckboxColumn(column) {
        const checkboxColumn = {
            field: column.name,
            headerName: column.label,
            cellEditor: 'agRichSelectCellEditor',
            cellEditorPopup: false,
            cellEditorParams: {
                values: [true, false],
                formatValue: value => value == true ? 'Waar' : 'Niet waar',
            },
            editable: true,
            sortable: true,
            cellStyle: {color: 'white'},
            resizable: true,
            floatingFilter: true,
            filter: 'agTextColumnFilter'
        }
        return checkboxColumn
    },

    getDropDownColumn(column, lineSection) {
        const dropDownColumn = {
            field: column.name,
            headerName: column.label,
            cellEditor: 'agRichSelectCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                values: store.state.dynamicModules[lineSection.lineType].dropDownValues[column.name],
            },
            editable: true,
            sortable: true,
            resizable: true,
            cellStyle: {color: 'white'},
            floatingFilter: true,
            filter: 'agTextColumnFilter'
        }
        return dropDownColumn
    },

    getDecimalColumn(column) {
        const decimalColumn = {
            field: column.name,
            headerName: column.label,
            cellEditor: 'decimalEditor',
            cellEditorParams: {},
            cellRendererParams: {
                color: "white"
            },
            editable: true,
            sortable: true,
            resizable: true,
            cellStyle: {color: 'white'},
            floatingFilter: true,
            aggFunc: 'sum',
            filter: 'agTextColumnFilter'
        }
        return decimalColumn
    },

    getRelatedColumn(column, formData, lineSection, section) {
        const relatedModule = store.state.dynamicModules[lineSection.lineType].fields[column.name].relatedModule
        const relatedColumn = {
            field: column.name,
            headerName: column.label,
            sortable: true,
            editable: true,
            cellEditor: "relatedFieldEditor",
            cellEditorPopup: true,
            cellEditorParams: {
                formData: formData,
                fname: column.name,
                flabel: column.label,
                field: store.state.dynamicModules[lineSection.lineType].fields[column.name],
                relatedModule: store.state.dynamicModules[relatedModule],
            },
            cellRenderer: "relatedFieldRenderer",
            relatedModule: store.state.dynamicModules[relatedModule],
            section: section,
            formData: formData,
            resizable: true,
            floatingFilter: true,
            filter: 'agTextColumnFilter'
        }
        return relatedColumn
    }
}

export default AgGridTable