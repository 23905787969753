
  import QFirebaseUploaderBase from "./QFirebaseUploaderBase";
  import axios from "axios";
  import {mapActions} from "vuex";

  export default {
    name: "MediaList",
    props: {
      media: {},
      stage: {},
      label: String,
      readonly: null,
      storageRef: String,
    },
    components: {
      QFirebaseUploaderBase,
    },
    data() {
      return {
        slide: 0,
        fullscreen: false,
        showDeleteDialog: false,
        imageFit: false,
        regenerateAttempt: [],
      };
    },
    methods: {
      ...mapActions(['getFileUrlFromStorage']),
      addMedia(media) {
        this.$refs.mediaUploader.removeQueuedFiles()
        const mediaData = media.data.map((el) => {
          el.stage = this.stage;
          return el;
        });
        this.$emit("addMedia", {data: mediaData, stage: this.stage});
      },
      removeItem() {
        this.fullscreen = false;
        this.$refs.carousel.exitFullscreen();
        this.imageFit = false;
        this.$emit("removeMedia", {data: this.media[this.slide]});
        this.slide = 0;
      },
      onClickFullscreen() {
        if (this.fullscreen) {
          this.fullscreen = false;
          this.imageFit = false;
        } else {
          this.fullscreen = true;
          this.imageFit = true;
        }
      },
      async doDownloadImage() {
        this.downLoadAttempts = 0;
        await this.downloadImage();
      },
      async downloadImage() {
        const media = this.media[this.$refs.carousel.value];
        if(!media.fileName || !media.fileName.length) {
          media.fileName = "Picture.jpg"
        }
        axios({
          url: media.downloadableURL,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', this.media[this.$refs.carousel.value].fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(() => {
          this.regenerateUrlAndDownload(media);
        });
      },
      async onImgError(media) {
        console.log('error image load', media);
        await this.regenerateUrl(media);
      },
      async regenerateUrl(media) {
        if (this.regenerateAttempt.includes(media.ID)) {
          return;
        }
        media.storageRef = this.storageRef;
        
        const downloadUrl = await this.getFileUrlFromStorage(media);
        
        this.regenerateAttempt.push(media.ID);
        if (downloadUrl) {
          media.downloadableURL = downloadUrl;
          this.$emit("updateMedia", media);
        }
      },
      async regenerateUrlAndDownload(media) {
        await this.regenerateUrl(media);
        await this.downloadImage();
      },
      clickEscape(event) {
        if (event.key === 'Escape') {
          this.fullscreen = false;
          this.imageFit = false;
        }
      }
    },
    mounted() {
      document.body.addEventListener("keydown", this.clickEscape);
    },
    beforeDestroy() {
      document.body.removeEventListener("keydown", this.clickEscape);
    }
  };
