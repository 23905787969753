
import BaseSubMixin from "@/components/Base/Mixin/BaseSubMixin";
import ListValues from '@/components/Mixin/ListValues'
import AbModel from "@/components/Models/AbModel";

export default {
  name: "BaseSectionSubForm",
  components: { DynamicSubForm: () => import('@/components/Forms/DynamicSubForm') },
  mixins: [ BaseSubMixin ],

  data() {
    return {
      loading: false,
      separator: "horizontal",
      selected: [],
      deleteBtnVisible: false,
      dynamicShowModalForm: false,
      modalFormID: null,
      subFormFieldData: {},
      deleteFormsModal: false,
      localPagination: {},
      displayedColumns: []
    };
  },
  methods: {
    customSort(rows, sortBy, descending) {
      return ListValues.customSort(rows, sortBy, descending);
    },
    addClick() {
      if (this.isSectionReadonly) {
        return false;
      }
      this.modalFormID = null;
      this.subFormFieldData = {};
      this.dynamicShowModalForm = true;
    },
    resetModalForm() {
      this.modalFormID = null;
      this.subFormFieldData = {};
      this.dynamicShowModalForm = false;
    },
    onRowDblClick(evt, row) {
      this.modalFormID = row.ID;
      this.subFormFieldData = this.formData.data[this.subFormField].filter(e => e.ID === row.ID)[0];
      this.dynamicShowModalForm = true;
      // TODO subFormFieldInfo does not have the sections
    },
    modalFormSave(data) {
      if (this.isSectionReadonly) {
        return false;
      }
      const noCloseModalForm = data.noCloseModalForm;
      const dataToSave = data.dataToSave;
      const inx = this.formData.data[this.subFormField].findIndex(e => e.ID === dataToSave.ID);
      if(inx >= 0 ) {
        this.formData.data[this.subFormField].splice(inx, 1, dataToSave);
      } else {
        this.formData.data[this.subFormField].splice(0, 0, dataToSave);
      }
      this.$emit("setUnsavedData");
      if (!noCloseModalForm) {
        this.resetModalForm();
      }
    },
    deleteForms() {
      if (this.isSectionReadonly) {
        return false;
      }
      this.selected.forEach(item => {
        const inx = this.formData.data[this.subFormField].findIndex(e => e.ID === item.ID);
        if(inx >= 0 ) {
          this.formData.data[this.subFormField].splice(inx, 1);
        }
      });
      this.selected = [];
      this.$emit("setUnsavedData");
    },
    // get visible columns for BaseSectionSubForm
    getDisplayedColumns () {
      for (const f in this.dynamicFields){
        if (!this.dynamicFields[f].hidden) this.displayedColumns.push(this.dynamicFields[f].name || f);
      }
    }
  },

  computed: {
    dynamicFields() {
      return this.subFormFieldInfo ? this.subFormFieldInfo.fields : {};
    },
    subFormFieldInfo() {
      return this.formData.dynamicFields[this.subFormField];
    },
    isfieldWiseDisabled() {
      const isAddDisabled = !this.formData.guard.check('update', this.module);
      if(isAddDisabled) return isAddDisabled;
      const fieldWiseContol = this.fieldWiseControl('DISABLED')
      if(fieldWiseContol) return fieldWiseContol;
      return false
    },
    data() {
      const model = new AbModel();
      const vals = this.formData.data && this.formData.data[this.subFormField]
        ? this.formData.data[this.subFormField].map(r => model.convertToObject(r))
        : [];
      return ListValues.transform(vals, this.dynamicFields, true);
    },
    align() {
      return "left";
    },
    isSectionHidden() {
      return this.section && this.section.hidden;
    },
    isSectionReadonly() {
      return this.section.readonly;
    },
  },
  mounted() {
    this.getDisplayedColumns()
  }
};
