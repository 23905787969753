

import tableDragger from "table-dragger";
import {exportFile, uid} from "quasar";
import {mapGetters} from "vuex";

  export default {
    props: {
      cleanSelected: null,
    },
    data: () => ({

    }),
    methods: {
      // open from firestore/DynamicTable
      openRelatedModal(props) {
        const relatedModule = props.col.name;
        const relatedID = props.row[props.col.name + '_ID'];
        if (relatedID) {
          this.showRelatedModalForm = true;
          this.modalRelatedFormID = relatedID;
          this.relatedModuleModal = this.getRelatedModule(relatedModule);
        }
      },
      openRelatedNoModal(props) {
        const relatedModule = props.col.name;
        const relatedID = props.row[props.col.name + '_ID'];
        if (relatedID) {
          const path = '/modules/' + this.getRelatedModule(props.col.name) + '/edit/' + props.row[props.col.name + '_ID'];
          this.$router.push(path);
        }
      },
      resetRelatedModalForm() {
        this.showRelatedModalForm = false;
        if (this.onRequest) {
          this.onRequest();
        }
      },
      async modalRelatedFormSave() {
        if (this.onRequest) {
          this.onRequest();
        }
      },
      isRelatedField(colName) {
        const df = this.dynFields[colName];
        return df && df.type === 'relatedField';
      },
      getRelatedModule(colName) {
        // split for PB table like as column.appointment
        const df = this.dynFields[colName];
        return df ? df.relatedModule : '';
      },
      getEditPath() {
        if (this.isPlanBoardMode) {
          return '/modules/appointments'
        } else {
          return this.$route.path;
        }
      },
      // Draggable
      doTableHeadersDragable() {
        if (this.draggerHeaders !== null) {
          this.draggerHeaders.destroy();
        }
        if (this.columnSortEnabled) {
          const div = document.getElementsByClassName('my-sticky-header-column-table')[0];
          const el = div.getElementsByClassName('q-table')[0];

          this.draggerHeaders = tableDragger(el, {
            mode: 'column',
            dragHandler: 'th',
            onlyBody: true,
          });

          this.draggerHeaders.on('drop', (from, to) => {
            this.$emit("onColumnDrop", {from, to});
          });
        }
      },
      exportTable(type) {
        // naive encoding to csv format
        const content = [this.columns.map(col => this.wrapCsvValue(col.label))].concat(
          this.data.map(row => this.columns.map(col => this.wrapCsvValue(
            typeof col.field === 'function'
              ? col.field(row)
              : row[col.field === void 0 ? col.name : col.field],
            col.format
          )).join(','))
        ).join('\r\n');

        const status = exportFile(
          this.file_name+'.' + type,
          content,
          'text/' + type
        );

        if (status !== true) {
          this.$q.notify({
            message: 'Browser denied file download...',
            color: 'negative',
            icon: 'warning'
          })
        }
      },
      wrapCsvValue(val, formatFn) {
        let formatted = formatFn !== void 0
          ? formatFn(val)
          : val;
        formatted = formatted === void 0 || formatted === null
          ? ''
          : String(formatted)
        formatted = formatted.split('"').join('""');
        /**
         * Excel accepts \n and \r in strings, but some other CSV parsers do not
         * Uncomment the next two lines to escape new lines
         */
        // .split('\n').join('\\n')
        // .split('\r').join('\\r')
        return `"${formatted}"`
      },
      getSelectedString() {
        return this.selected.length === 0
          ? ""
          : `${this.selected.length} record${
            this.selected.length > 1 ? "s" : ""
          } ${this.$t("table.misc.selectedOf")} ${this.data.length}`;
      },
      getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
        return `${firstRowIndex}-${endRowIndex} ${this.$t(
          "table.misc.of"
        )} ${totalRowsNumber}`;
      },
    },

    watch: {
      selected() {
        this.$emit("onSelected", this.selected);
      },
      cleanSelected() {
        this.selected = this.cleanSelected;
      },
      columns() {
        // the product lines has not the setColumnFilterOptions
        // TODO should do it as setColumnFilterOptions, anyway the same need
        if (this.setColumnFilterOptions) {
          for(const column of this.columns) {
            if(column.type === "dropdown") {
              column["filterType"] = "select"
            }
          }
          this.setColumnFilterOptions(this.columns);
        }
      },
      visibleDefaultColumns() {
        if (this.visibleDefaultColumns) {
          this.visibleColumns = this.visibleDefaultColumns;
        }
      },
      togleRefreshList() {
        this.currentRefreshKey++;
        this.refreshList();
        setTimeout(this.doTableHeadersDragable, 1000);
      },
      columnSortEnabled() {
        this.doTableHeadersDragable();
      },
      filteredDataSaved() {
        if (this.filteredDataSaved) {
          this.filterSettingLoaded = true;
          this.columnOptionsSelected = this.filteredDataSaved.columnOptionsSelected ?? {};
          this.filterData = this.filteredDataSaved.filterData ?? {};
        }
      },
    },
    computed: {
      ...mapGetters(["dynamicModulesData"]),
      tabColumns() {
        if (this.isPlanBoardMode) {
          return [{
            name: 'ID',
            align: this.align,
            label: 'ID',
            field: 'ID',
          }].concat(this.columns);
        } else {
          return this.columns;
        }
      },
      tabVisibleColumns() {
        if (this.isPlanBoardMode) {
          return ['ID'].concat(this.visibleColumns);
        } else {
          return this.visibleColumns;
        }
      },
      align() {
        return "left";
      },
      moduleToSaveSettings() {
        return this.moduleSettings || this.module;
      },
      isShowMultiSelection() {
        return this.openAsRelated ? 'none' : 'multiple';
      },
      isShowColumnFilter() {
        return !this.openAsRelated;
      },
      isShowHeaderFilter() {
        return false;
      },
      showTable() {
        return this.defaultPagination && this.columns;
      }
    },
    // QGrid
    created() {
      this.uuid = uid();
      if (!this.file_name) {
        this.file_name = this.name || this.module;
      }
    },
  };
