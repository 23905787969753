

  import BaseExtend from "@/components/Base/Mixin/BaseExtend";
  import EmailMessages from "@/components/Nylas/EmailMessages";
  import TemplateEmailForm from "@/components/Settings/Sections/Templates/EmailTemplates/Components/TemplateEmailForm";
  import {mapState} from "vuex";

  export default {
    name: "BaseSectionEmails",
    components: { EmailMessages, TemplateEmailForm },
    mixins: [ BaseExtend ],
    props: {
      formData : {},
      label: null,
      name: null,
      section: {},
      emailField: null,
      threadIDField: null,
      showTemplateEmailForm: {},
      tenantEmailsTemplates: null,
      userEmailsTemplates: null,
    },
    data() {
      return {
        emailTypes: [
          {
            id: 0,
            type: 'thread',
            name: this.$t('email.fields.allEmailsWithWorkOrderReference'),
          },
          {
            id: 1,
            type: 'account',
            name: this.$t('email.fields.allEmailsToThisAccount'),
          },
        ],
        choosedEmailTypeId: 0
      };
    },
    computed: {
      ...mapState('settings', ["userEmailSettings"]),
      threadID() {
        return this.choosedEmailTypeId === 0 && this.threadIDField ? this.formData.data[this.threadIDField] : '';
      },
      emailCustomer() {
        return this.emailField ? this.formData.data[this.emailField] : '';
      },
      pdfToSend() {
        return this.showTemplateEmailForm ? this.showTemplateEmailForm.pdfToSend : null;
      },
      isShowTemplateEmailForm: {
        get() {
          return this.showTemplateEmailForm ? this.showTemplateEmailForm.value : false;
        },
        set() {
          this.showTemplateEmailForm.value = false;
          this.showTemplateEmailForm.pdfToSend = null;
        }
      }
    }
  };
