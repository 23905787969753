import { render, staticRenderFns } from "./AttachmentsTable.vue?vue&type=template&id=1e51dd5c&scoped=true&"
import script from "./AttachmentsTable.vue?vue&type=script&lang=ts&"
export * from "./AttachmentsTable.vue?vue&type=script&lang=ts&"
import style0 from "./AttachmentsTable.vue?vue&type=style&index=0&id=1e51dd5c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e51dd5c",
  null
  
)

export default component.exports
import {QSpace,QBtn,QMenu,QList,QItem,QItemSection,QIcon,QMarkupTable,QTable,QCheckbox,QSeparator,QChip,QPopupEdit,QDialog,QCard,QCardSection,QInput,QCardActions,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSpace,QBtn,QMenu,QList,QItem,QItemSection,QIcon,QMarkupTable,QTable,QCheckbox,QSeparator,QChip,QPopupEdit,QDialog,QCard,QCardSection,QInput,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
