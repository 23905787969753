
  import { Component, Vue, Prop, Watch } from "vue-property-decorator";
  import Dropzone from "@/components/Nylas/Dropzone.vue";

  @Component({
    components: { Dropzone }
  })
  export default class EmailAttachments extends Vue {
    @Prop(Object) data;
    @Prop(Object||null) pdfToSend;
    @Prop(Array||null) addToAttaches;

    files = [];

    mounted() {
      this.files = this.data.attachments.concat(this.data.media);
      if (this.pdfToSend) {
        this.addPdfToSendToAttach(this.pdfToSend);
      }
    }

    addPdfToSendToAttach(pdf) {
      // eslint-disable-next-line
      // @ts-ignore: Unreachable code error
      this.$refs.EmailAttachDropZone.addToDropZonePDF(pdf);
    }

    addFiles(data) {
      this.$emit("addFiles", data);
    }

    removedFile(data) {
      this.$emit("removedFile", data);
    }

    addToDropZone(file) {
      const addFile = { ...file, ...{ size: file.fileSize, name: file.fileName }};
      const url = file.downloadableURL;
      // eslint-disable-next-line
      // @ts-ignore: Unreachable code error
      this.$refs.EmailAttachDropZone.addToDropZone(addFile, url);
    }

    @Watch('addToAttaches')
    handleSelect(newValue) {
      
      if (newValue) {
        newValue.forEach(f => this.addToDropZone(f));
      }
    }

  }
