

  import BaseExtend from "@/components/Base/Mixin/BaseExtend";

  import {mapActions} from 'vuex';
  import {asyncForEach} from "@/common/helpers/async-for-each";
  import QFirebaseUploaderBase from "@/components/Parts/QFirebaseUploaderBase.vue";
  import AttachmentsTable from "@/components/Parts/AttachmentsTable.vue";
  import {EventBus} from "@/event-bus";
  import store from "@/store";

  export default {
    name: "BaseSectionAttachment",
    components: {
      AttachmentsTable, QFirebaseUploaderBase
    },
    mixins: [ BaseExtend ],
    data() {
      return {
        updateProgress: {}
      };
    },
    methods: {
      ...mapActions(['uploadFileToStorage']),
      ...mapActions(['deleteFileFromStorage']),

      async onSave(needCallbackAlert = true, newAttachments = []) {
        this.formData.noCloseModalForm = true;
        if (this.formData.data.ID) {
          await this.uploadFilesToFirestore(newAttachments);
        } else {
          this.formData.callbackAfterSave = async (id) => {
            this.formData.data.ID = id;
            await this.uploadFilesToFirestore(newAttachments);
            this.formData.callbackAfterSave = null;
            this.formData.noCloseModalForm = true;
            this.saveTheForm(false);
          }
        }
        this.saveTheForm(needCallbackAlert);
      },

      async uploadFilesToFirestore(newAttachments) {
        await asyncForEach(newAttachments, async (file) => {
          file.storageRef = `${this.formData.module}/records/${this.formData.data.ID}/attachments/`;
          //TODO show progress
          file.procentShowCallBack = () => {
            // const loaded = Math.min(snapshot.totalBytes, snapshot.bytesTransferred)
            //this.uploadedSize += loaded - file.__uploaded
            //this.updateFile(file, 'uploading', loaded)
          };
          const uploadedFile = await this.uploadFileToStorage(file);
          this.formData.data[this.name].push(uploadedFile);
        });
      },

      async addAttachments(attachments) {
        this.$refs.attachmentUploader.removeQueuedFiles()
        attachments.data.forEach(a => { a.tags = [] });
        await this.onSave(false, attachments.data);
      },

      editAttachments(attachments) {
        this.formData.data[this.name] = attachments.data;
        this.onSave(false);
      },

      removeAttachment(attachment) {
        this.formData.data[this.name] = this.formData.data[this.name].filter(
          (el) => el.ID !== attachment.data.ID
        );
        this.deleteFileFromStorage(
          `${this.formData.module}/records/${this.formData.data.ID}/attachments/${attachment.data.ID}`
        );
        this.onSave(false);
      },

      saveTheForm(needCallbackAlert = true) {
        const addSettings = {};
        addSettings.updateOnlyFields = [this.name];
        this.$emit('saveTheForm', needCallbackAlert, addSettings);
      },

      async savePreviewPDF(file) {
        const attachments = {
          data: [file]
        }
        await this.addAttachments(attachments);
        store.state.alertMessage = "update";
      }
    },
    computed: {
      isFieldWiseDisabled() {
        const isAddDisabled = !this.formData.guard.check('update', this.module);
        if(isAddDisabled) return true;
        const isSectionReadOnly = this.isSectionReadonly 
        if(isSectionReadOnly) return true;
        const fieldWiseControl = this.fieldWiseControl('DISABLED')
        if(fieldWiseControl) return true;
        return false
      },
      attachments() {
        return this.formData.data[this.name];
      },
      preTags() {
        const tags = this.section.tags || [];
        return tags;
      }
    },
    async beforeMount() {
      EventBus.$on('needToSavePDFToAttachment', this.savePreviewPDF);
    },
    beforeDestroy() {
      EventBus.$off('needToSavePDFToAttachment', this.savePreviewPDF);
    }
  };
