
  import { QUploaderBase } from "quasar";

  export default {
    mixins: [QUploaderBase],
    props: {
      uploadType: null,
    },
    data() {
      return {
      };
    },
    methods: {
      async upload() {
        const attachments = [...this.files];
        this.$emit("upload", { data: attachments });
      },
    },
  };
