

  import BaseExtend from "@/components/Base/Mixin/BaseExtend";

  import {mapActions} from 'vuex';
  import {asyncForEach} from "@/common/helpers/async-for-each";
  import MediaList from "@/components/Parts/MediaList.vue";

  export default {
    name: "BaseSectionMedia",
    components: {
      MediaList
    },
    mixins: [BaseExtend],

    data() {
      return {
        firstLabel: '',
        secondLabel: '',
        thirdLabel: ''
      };
    },
    mounted() {
      this.firstLabel = this.formData.dynamicSections[this.section.name].firstLabel ? this.formData.dynamicSections[this.section.name].firstLabel : this.$t('attachments.headers.mediaBefore')
      this.secondLabel = this.formData.dynamicSections[this.section.name].secondLabel ? this.formData.dynamicSections[this.section.name].secondLabel : this.$t('attachments.headers.mediaDuring')
      this.thirdLabel = this.formData.dynamicSections[this.section.name].thirdLabel ? this.formData.dynamicSections[this.section.name].thirdLabel : this.$t('attachments.headers.mediaAfter')
    },
    methods: {
      ...mapActions(['uploadFileToStorage']),
      ...mapActions(['deleteFileFromStorage']),

      async onSave(needCallbackAlert = true, newMedia = []) {
        if (this.formData.data.ID) {
          await this.uploadFilesToFirestore(newMedia);
        } else {
          this.formData.callbackAfterSave = async (id) => {
            this.formData.data.ID = id;
            await this.uploadFilesToFirestore(newMedia);
            this.formData.callbackAfterSave = null;
            this.saveTheForm(false);
          }
        }
        this.saveTheForm(needCallbackAlert);
      },

      async uploadFilesToFirestore(newMedia) {
        await asyncForEach(newMedia, async (file) => {
          file.storageRef = this.storageRef;
          //TODO show progress
          file.procentShowCallBack = () => {
            //const loaded = Math.min(snapshot.totalBytes, snapshot.bytesTransferred)
            //this.uploadedSize += loaded - file.__uploaded
            //this.updateFile(file, 'uploading', loaded)
          };
          const uploadedFile = await this.uploadFileToStorage(file);
          this.formData.data[this.name].push(uploadedFile);
        });
      },

      addMedia(media) {
        //this.formData.data[this.name] = (this.formData.data[this.name] || [])
          //.filter((el) => el.stage !== media.stage)
          //.concat(media.data);
          this.onSave(false, media.data);
      },
      removeMedia(mediaItem) {
        this.formData.data[this.name] = (this.formData.data[this.name] || []).filter(
          (el) => el.ID !== mediaItem.data.ID
        );
        this.deleteFileFromStorage(
          `${this.formData.module}/records/${this.formData.data.ID}/media/${mediaItem.data.ID}`
        );
        this.onSave(false);
      },
      updateMedia() {
        this.saveTheForm(false);
      },
      saveTheForm(needCallbackAlert = true) {
        this.$emit('saveTheForm', needCallbackAlert);
      }
    },
    computed: {
      isFieldWiseDisabled() {
        const isAddDisabled = !this.formData.guard.check('update', this.module);
        if(isAddDisabled) return true;
        const isSectionReadOnly = this.isSectionReadonly 
        if(isSectionReadOnly) return true;
        const fieldWiseControl = this.fieldWiseControl('DISABLED')
        if(fieldWiseControl) return true;
        return false
      },
      mediaBefore() {
        if (this.formData.data[this.name]) {
          return this.formData.data[this.name].filter((el) => el.stage === "1");
        } else {
          return [];
        }
      },
      mediaDuring() {
        if (this.formData.data[this.name]) {
          return this.formData.data[this.name].filter((el) => el.stage === "2");
        } else {
          return [];
        }
      },
      mediaAfter() {
        if (this.formData.data[this.name]) {
          return this.formData.data[this.name].filter((el) => el.stage === "3");
        } else {
          return [];
        }
      },
      storageRef() {
        return `${this.formData.module}/records/${this.formData.data.ID}/media/`;
      }
    }
  };
