

  export default {
    name: "ListViewSetting",
    props: {
      section: null,
      allColumnsForSelect: null,
      defVisibleColumns: null,
      canRefresh: null,
      loading: null,
      readonly: null,
    },
    data() {
      return {
        columnSortEnabled: false,
        visibleColumns: this.defVisibleColumns,
      };
    },
    methods: {
      saveColumnSettings() {
        this.$emit("setColumns", this.visibleColumns);
      },
      doListRefresh() {
        this.$emit("onListRefresh");
      },
    },
    watch: {
      columnSortEnabled() {
        this.$emit("onColumnSortEnabled", this.columnSortEnabled);
      },
    }
  };
