
import {mapActions, mapState} from "vuex";
import ListValues from "@/components/Mixin/ListValues";
import moment from "moment";
import tableDragger from "table-dragger";
import AbModel from "@/components/Models/AbModel";
import store from "@/store";

export default {
  data: () => ({
    loading: false,
    separator: "horizontal",
    localFormListPagination: {
      sortBy: "changedAt",
      descending: true
    },
    oldLocalFormListPagination: {},
    draggerHeaders: null,
    currentRefreshKey: 1,
    selected: [],
    deleteBtnVisible: false,
    queryRequestData: [],
    showForm: false,
    modalFormID: null,
    modalFormObjData: null,
    deleteFormsModal: false,
  }),
  methods: {
    ...mapActions('settings', ["saveFormListViews", "getFormListViews"]),
    openRelatedModal(props) {
      const relatedModule = props.col.name;
      const relatedID = props.row[props.col.name + '_ID'];
      if (relatedID) {
        this.showRelatedModalForm = true;
        this.modalRelatedFormID = relatedID;
        this.relatedModuleModal = this.getRelatedModule(relatedModule);
      }
    },
    openRelatedModalFromAgGrid(props) {
      store.state.relatedFieldClicked = true
      const relatedRecord = props.data[props.colDef.field+ '_ID']
      const relatedModule = props.colDef.relatedModule
      if (relatedRecord) {
        this.modalRelatedFormID = relatedRecord;
        this.relatedModuleModal = relatedModule.id;
        this.showRelatedModalForm = true;
      }
    },
    resetRelatedModalForm() {
      this.showRelatedModalForm = false;
      if (this.onRequest) {
        this.onRequest();
      }
    },
    async modalRelatedFormSave() {
      if (this.onRequest) {
        this.onRequest();
      }
    },
    isRelatedField(colName) {
      const df = this.dynFields[colName];
      return df && df.type === 'relatedField';
    },
    getRelatedModule(colName) {
      const df = this.dynFields[colName];
      return df ? df.relatedModule : '';
    },
    setVisibleFormListColumns(cols) {
      this.saveFormListViews({module: this.moduleNameForFormSetting, data: {columns: cols}}).then(() => {
        this.getFormListViews().then(() => {
          if (this.onListRefreshForTotals) {
            this.onListRefreshForTotals();
          }
        });
      })
    },
    setFormListPagination() {
      const pagOld = this.oldLocalFormListPagination;
      const pagination = this.localFormListPagination;
      if (
        pagOld.oldRowsPerPage && pagOld.oldRowsPerPage !== pagination.rowsPerPage ||
        pagOld.oldSortBy && pagOld.oldSortBy !== pagination.sortBy ||
        pagOld.oldDescending !== undefined && pagOld.oldDescending !== pagination.descending
      ) {
        
        this.saveFormListViews({
          module: this.moduleNameForFormSetting, data: {
            pagination: {
              sortBy: pagination.sortBy,
              descending: pagination.descending,
              rowsPerPage: pagination.rowsPerPage
            }
          }
        }).then(() => {
          this.getFormListViews();
        })
      }
      pagOld.oldRowsPerPage = pagination.rowsPerPage;
      pagOld.oldSortBy = pagination.sortBy;
      pagOld.oldDescending = pagination.descending;
    },
    onColumnSortEnabled(enable) {
      this.doTableHeadersDragable(enable)
    },
    doTableHeadersDragable(enable = true) {
      if (this.draggerHeaders !== null) {
        this.draggerHeaders.destroy();
      }
      if (enable) {
        const div = document.getElementsByClassName('table-column-order-' + this.section.name)[0];
        const el = div.getElementsByClassName('q-table')[0];

        this.draggerHeaders = tableDragger(el, {
          mode: 'column',
          dragHandler: 'th',
          onlyBody: true,
        });

        this.draggerHeaders.on('drop', (from, to) => {
          this.onColumnDropForm({from, to});
        });
      }
    },
    onColumnDropForm(pos) {
      pos.from -= 1;
      pos.to -= 1;
      const columns = this.userFormsSettings[this.moduleNameForFormSetting].columns;
      const val = columns[pos.from];
      columns.splice(pos.from, 1);
      columns.splice(pos.to, 0, val);
      this.setVisibleFormListColumns(columns);
      this.currentRefreshKey++;
      
      setTimeout(this.doTableHeadersDragable, 1000);
    },
    getAllColumnsForSelect() {
      const cols = [];
      const fields = ListValues.getValidColumns(this.getAllFields());
      for (const column in fields) {
        cols.push({name: column, label: fields[column].label || column});
      }
      return cols.sort(((a, b) => {
        return a.order - b.order
      }));
    },
    getAllFields(fields1 = {}, fields2 = {}) {
      return {...this.dynFields, ...fields1, ...fields2};
    },
    onSelected(selected) {
      this.selected = selected;
    },
    addClick() {
      if (this.isSectionReadonly) {
        return false;
      }
      this.modalFormID = null;
      this.formData.copyToNewForm = this.section.copyToNewForm;
      this.showForm = true;
    },
    resetModalForm() {
      this.refreshList();
      this.modalFormID = null;
      this.showForm = false;
    },
    async modalFormSave() {
      this.refreshList();
    },
    deleteForms() {
      if (this.isSectionReadonly) {
        return false;
      }
      const needToDelDB = !this.localDeleteListItems(this.selected);
      if (needToDelDB) {
        this.selected.forEach((e) => {
          new AbModel().delete(e.ID, this.currentModule)
            .then((res) => {
              if(res === 'locked') {
                store.state.alertMessage = "lockedOnDelete";
              }
              this.refreshList();
            });
        });
      }
      this.selected = [];
    },
    localDeleteListItems() {
      return false;
    },
    localOnRowClick() {
      return false;
    },
    refreshList() {
      this.localFormListPagination.page = 1;
      this.onRequest();
    },
    onListRefresh() {
      this.refreshList();
    },
    onRowClick(evt, row) {
      if (this.localOnRowClick) {
        this.localOnRowClick(evt, row);
      }
      this.modalFormID = row.ID;
      this.showForm = true;
    },
    onRowDblClick(evt, row) {
      if (this.localOnRowClick) {
        this.localOnRowClick(evt, row);
      }
      this.modalFormID = row.ID;
      this.showForm = true;
    },
    getSelectedString() {
      return this.selected.length === 0
        ? ""
        : `${this.selected.length} record${
          this.selected.length > 1 ? "s" : ""
        } ${this.$t("table.misc.selectedOf")} ${this.data.length}`;
    },
    getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
      return `${firstRowIndex}-${endRowIndex} ${this.$t(
        "table.misc.of"
      )} ${totalRowsNumber}`;
    },
  },
  watch: {
    selected() {
      this.deleteBtnVisible = this.selected.length > 0;
      this.$emit("onSelected", this.selected);
    },
    localFormListPagination() {
      this.setFormListPagination();
    },
    columns() {
      
      if (this.setColumnFilterOptions) {
        this.setColumnFilterOptions(this.columns);
      }
    },
  },
  mounted() {
    
    this.localFormListPagination = {...this.localFormListPagination, ...this.storedFormListPagination || {}};
    this.onRequest();
  },
  computed: {
    ...mapState(["dynamicModules"]),
    ...mapState("settings", ["userFormsSettings", "tenantDefaultColumns"]),
    data() {
      return this.queryRequestData;
    },
    columns() {
      const sortable = !this.columnSortEnabled;
      const cols = [];
      const fields = this.getAllFields();
      const locVisibleColumns = this.visibleFormListColumns;

      

      if (locVisibleColumns) {
        locVisibleColumns.filter(c => fields[c]).map((column) => {
          const label = fields[column].label ? fields[column].label : column;
          const dateType = fields[column].type === 'date' || fields[column].type === 'datetime';
          const newCol = {
            name: column,
            align: this.align,
            label: label,
            field: column,
          };
          if (sortable) {
            newCol.sortable = sortable;
            if (dateType) {
              newCol.sort = (a, b) => {
                
                const d1 = moment(a, 'DD-MM-yyyy HH:mm');
                const d2 = moment(b, 'DD-MM-yyyy HH:mm');
                if (d1.isAfter(d2)) {
                  return 1;
                } else if (d1.isBefore(d2)) {
                  return -1;
                }
                return 0;
              };
            }
          }
          if ((this.dropDownValues && this.dropDownValues[column]) ||
            (fields[column] && fields[column].type === 'checkbox')) {
            newCol.filterType = 'select';
            newCol.type = fields[column].type;
          }
          cols.push(newCol);
        });
      }
      return cols;
    },
    // get visible columns for BaseSectionRelatedRecords, sub records and product lines
    visibleFormListColumns: {
      get() {
        let vColumns = this.userFormsSettings[this.moduleNameForFormSetting] && this.userFormsSettings[this.moduleNameForFormSetting].columns
          ? this.userFormsSettings[this.moduleNameForFormSetting].columns
          : [];
        if (!vColumns.length) {
          vColumns = this.tenantDefaultColumns[this.relatedOrLineModule] || [];
        }
        const hiddenFields = []
        for (const f in this.dynFields) {
          if (this.dynFields[f].hidden) hiddenFields.push(this.dynFields[f].name);
        }
        vColumns = vColumns.filter(e => !hiddenFields.includes(e));
        
        return vColumns;
      }
    },
    storedFormListPagination() {
      const pagination = this.userFormsSettings[this.moduleNameForFormSetting] && this.userFormsSettings[this.moduleNameForFormSetting].pagination
        ? this.userFormsSettings[this.moduleNameForFormSetting].pagination
        : null;
      return pagination;
    },
    moduleNameForFormSetting() {
      const mName = this.formData.module + '-' + this.section.name;
      
      return mName;
    },
    module() {
      return this.dynModuleName;
    },
    relatedOrLineModule() {
      return this.lineModule || this.relatedModule;
    },
    dynModuleName() {
      return this.relatedModule;
    },
    dynModuleData() {
      return this.dynamicModules && this.dynamicModules[this.dynModuleName] ? this.dynamicModules[this.dynModuleName] : {};
    },
    dynTitle() {
      return this.dynModuleData ? this.dynModuleData.name : null;
    },
    dynFields() {
      return this.dynModuleData && this.dynModuleData.fields
        ? {...ListValues.locFields(), ...this.dynModuleData.fields}
        : {};
    },
    dropDownValues() {
      const mData = store.state.dynamicModules[this.dynModuleName];
      return mData ? mData.dropDownValues : {};
    },
    allColumnsForSelect() {
      return this.getAllColumnsForSelect();
    },
    align() {
      return "left";
    },
  }
};
